import React, { useState, useEffect, useRef, useCallback } from 'react';
import Waitlist from './components/Waitlist';
import Star from './components/Star';
import ShootingStar from './components/ShootingStar';

function interpolateColor(color1: string, color2: string, factor: number): string {
  const c1 = parseInt(color1.slice(1), 16);
  const c2 = parseInt(color2.slice(1), 16);

  const r1 = (c1 >> 16) & 0xff;
  const g1 = (c1 >> 8) & 0xff;
  const b1 = c1 & 0xff;

  const r2 = (c2 >> 16) & 0xff;
  const g2 = (c2 >> 8) & 0xff;
  const b2 = c2 & 0xff;

  const r = Math.round(r1 + (r2 - r1) * factor);
  const g = Math.round(g1 + (g2 - g1) * factor);
  const b = Math.round(b1 + (b2 - b1) * factor);

  return `rgb(${r}, ${g}, ${b})`;
}

interface StarType {
  id: number;
  x: number;
  y: number;
  size: number;
  depth: number;
  shape: string;
  color: string;
  points: number;
}

function App() {
  // State variables
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollContainerRefRight = useRef<HTMLDivElement>(null);

  const [stars, setStars] = useState<StarType[]>([]);
  const [shootingStars, setShootingStars] = useState<{ id: number }[]>([]);

  // Helper functions
  const getRandomShape = () => {
    const shapes = ['dot', 'star', 'sparkle', 'starburst'];
    return shapes[Math.floor(Math.random() * shapes.length)];
  };

  const getRandomColor = () => {
    const colors = ['#ffffff', '#ffe9c4', '#d4fbff'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const removeStar = useCallback((id: number) => {
    setStars((prevStars) => prevStars.filter((star) => star.id !== id));
  }, []);

  const removeShootingStar = (id: number) => {
    setShootingStars((prev) => prev.filter((star) => star.id !== id));
  };

  useEffect(() => {
    let ticking = false;


    const handleScroll = (e: Event) => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const container = e.target as HTMLElement;
          const scrolled = container.scrollTop;
          const h = container.clientHeight;
          const totalTransitionHeight = h * 2; // Height of sections 1 to 3
          const scrollPos = Math.min(scrolled / totalTransitionHeight, 1);
          setScrollPosition(scrollPos);

          // Scroll the rectangles container
          const scrollContainer = scrollContainerRef.current;
          if (scrollContainer) {
            const containerWidth = scrollContainer.scrollWidth - scrollContainer.clientWidth;
            const scrollLeft = scrolled * 0.3; // Adjust the multiplier to control the scroll speed

            // Loop the scroll position
            if (scrollLeft >= containerWidth) {
              scrollContainer.scrollLeft = 0;
            } else if (scrollLeft <= 0) {
              scrollContainer.scrollLeft = containerWidth;
            } else {
              scrollContainer.scrollLeft = scrollLeft;
            }
          }

          // Scroll the second rectangles container in the opposite direction
          const scrollContainer2 = scrollContainerRefRight.current;
          if (scrollContainer2) {
            const containerWidth = scrollContainer2.scrollWidth - scrollContainer2.clientWidth;
            const scrollRight = (container.scrollHeight - container.clientHeight - scrolled) * 0.3;

            // Loop the scroll position
            if (scrollRight <= 0) {
              scrollContainer2.scrollLeft = 0;
            } else if (scrollRight >= containerWidth) {
              scrollContainer2.scrollLeft = containerWidth;
            } else {
              scrollContainer2.scrollLeft = scrollRight;
            }
          }

          ticking = false;
        });
        ticking = true;
      }
    };

    const container = document.getElementById('scroll-container');
    container?.addEventListener('scroll', handleScroll, { passive: true });

    // Star creation logic
    const createStar = () => {
      const id = Date.now() + Math.random(); // Ensure unique ID
      const depth = Math.random() * 0.5 + 0.5; // Depth between 0.5 and 1

      const shape = getRandomShape();

      const sizeMultiplier = shape === 'star' ? 1 : 1; // Increase size for SVG star

      const size = (Math.random() * 15 + 10) * depth * sizeMultiplier; // Size between 10 and 20 (or larger for stars)

      const newStar: StarType = {
        id,
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight,
        size,
        depth,
        shape,
        color: getRandomColor(),
        points: 4
      };

      setStars((prevStars) => [...prevStars, newStar]);

      // Schedule next star creation
      setTimeout(createStar, Math.random() * 1000);
    };

    createStar();

    // Shooting star creation logic
    const createShootingStar = () => {
      const id = Date.now() + Math.random(); // Ensure unique ID
      setShootingStars((prev) => [...prev, { id }]);

      // Schedule next shooting star
      setTimeout(createShootingStar, Math.random() * 10000 + 5000); // Every 5-15 seconds
    };

    createShootingStar();

    // Cleanup function
    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Background color transition based on scroll position
  let backgroundColor = '';
  if (scrollPosition <= 0.5) {
    // Transition from black to dark blue
    const factor = scrollPosition / 0.5; // Ranges from 0 to 1
    backgroundColor = interpolateColor('#2E2E2E', '#2A2B4F', factor);
  } else {
    // Transition from dark blue to white
    const factor = (scrollPosition - 0.5) / 0.5; // Ranges from 0 to 1
    backgroundColor = interpolateColor('#2A2B4F', '#F5F5F5', factor);
  }

  return (
    <>
      {/* Starry Background */}
      <div className="background-container absolute top-0 left-0 w-full h-full pointer-events-none">
        {shootingStars.map((star) => (
          <ShootingStar key={star.id} id={star.id} onAnimationEnd={removeShootingStar} />
        ))}
        {stars.map((star) => (
          <Star
            key={star.id}
            id={star.id}
            x={star.x}
            y={star.y}
            size={star.size}
            depth={star.depth}
            shape={star.shape}
            color={star.color}
            points={star.points}
            onAnimationEnd={removeStar}
          />
        ))}
      </div>

      {/* Main Content */}
      <div
        id="scroll-container"
        className="h-screen overflow-y-scroll snap-y snap-proximity"
        style={{ backgroundColor }}
      >
        {/* Section 0 */}
        <section
          data-index="0"
          className="content-section h-screen snap-start text-white flex items-center relative"
        >
            <div className="container mx-auto px-4">
            <h2 className="text-4xl md:text-6xl font-bold mb-6 md:mb-12 text-center px-2 md:px-8">Current State of Gaming</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 px-4 md:px-40">
              <div className="space-y-3 md:space-y-4">
              <h3 className="text-xl md:text-2xl font-bold">
                  Lazy{' '}
                  <span className="bg-gradient-to-r from-violet-500 via-indigo-500 via-blue-500 via-green-500 via-yellow-500 via-orange-500 to-red-500 bg-clip-text text-transparent">
                    Premium
                  </span>{' '}
                  Content
                </h3>
                <p className="text-gray-400 text-sm md:text-base">Explore how current gaming content often falls short of expectations despite premium pricing.</p>
              </div>
              <div className="space-y-4">
                <h3 className="text-2xl font-bold">Dead{' '}
                  <span className="bg-gradient-to-r from-violet-500 via-indigo-500 via-blue-500 via-green-500 via-yellow-500 via-orange-500 to-red-500 bg-clip-text text-transparent">
                  NPCs
                  </span>
                  </h3>
                <p className="text-gray-400">Understanding the limitations of traditional NPC behaviour and interactions.</p>
              </div>
              <div className="space-y-4">
                <h3 className="text-2xl font-bold">
                  <span className="bg-gradient-to-r from-violet-500 via-indigo-500 via-blue-500 via-green-500 via-yellow-500 via-orange-500 to-red-500 bg-clip-text text-transparent">
                  Performance
                  </span>{' '}Issues</h3>
                <p className="text-gray-400">Examining the technical challenges facing modern gaming experiences.</p>
              </div>
            </div>
          </div>
        </section>

        {/* Section 1 */}
        <section
          data-index="1"
          className="content-section h-screen snap-start text-white flex items-center relative"
        >
            <div className="container mx-auto px-4">
            <h2 className="text-4xl md:text-6xl font-bold mb-6 md:mb-12 text-center px-2 md:px-8">The AI Revolution</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-12 px-4 md:px-40">
              <div className="space-y-4 md:space-y-6">
                <h3 className="text-2xl font-bold">History of AI in Gaming</h3>
                <p className="text-gray-300">A comprehensive look at how artificial intelligence has evolved in gaming, from simple scripts to complex behaviour systems.</p>
              </div>
              <div className="space-y-6">
                <h3 className="text-2xl font-bold">
                  <span className="bg-gradient-to-r from-violet-500 via-indigo-500 via-blue-500 via-green-500 via-yellow-500 via-orange-500 to-red-500 bg-clip-text text-transparent">
                  Simulated
                  </span>: A Breakthrough</h3>
                <p className="text-gray-300">Discover how our technology optimises the delicate balance between cost, scope, and time in game development.</p>
              </div>
            </div>
          </div>
        </section>

        {/* Section 2 */}
        <section
          data-index="2"
          className="content-section h-screen snap-start text-black flex items-center relative"
        >
            <div className="container mx-auto px-4">
            <h2 className="text-4xl md:text-6xl font-bold mb-6 md:mb-12 text-center px-2 md:px-8">
                <span className="bg-gradient-to-r from-violet-500 via-indigo-500 via-blue-500 via-green-500 via-yellow-500 via-orange-500 to-red-500 bg-clip-text text-transparent">
                Our Solution
                </span></h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 px-4 md:px-40">
              <div className="space-y-3 md:space-y-4">
                <h3 className="text-2xl font-bold">Iterative NPCs</h3>
                <p className="text-gray-600">Revolutionary approach to NPC development through continuous learning and adaptation.</p>
              </div>
              <div className="space-y-4">
                <h3 className="text-2xl font-bold">Agentic Narrative Control</h3>
                <p className="text-gray-600">Enhanced player agency, immersion, and progression through dynamic storytelling.</p>
              </div>
              <div className="space-y-4">
                <h3 className="text-2xl font-bold">Modern Development</h3>
                <p className="text-gray-600">Moving beyond waterfall to an agile, iterative development approach.</p>
              </div>
            </div>
          </div>
        </section>
        <Waitlist />
      </div>
    </>
  );
}

export default App;
