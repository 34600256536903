import React, { memo } from 'react';

interface AIStarSVGProps {
  x: number;
  y: number;
  size: number;
  color: string;
  rotation: number;
  depth: number;
}

const AIStarSVG: React.FC<AIStarSVGProps> = ({
  x,
  y,
  size,
  color,
  rotation,
  depth,
}) => {
  return (
    <svg
      className="absolute twinkle"
      style={{
        left: x,
        top: y,
        width: size,
        height: size,
        opacity: depth,
        overflow: 'visible',
      }}
      viewBox="0 0 110 314"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 157C45.7122 157.17 53.5825 119.497 55 -8.17632e-06C54.8781 119.789 64.4845 156.726 110 157C64.7976 156.645 55.3146 194.965 55 314C55.6334 195.094 44.9393 156.225 0 157Z"
        fill={color}
      />
    </svg>
  );
};

export default memo(AIStarSVG);
