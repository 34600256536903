import React, { useState, useEffect, memo } from 'react';
import { StarType } from '../types';
import AIStarSVG from './AIStarSVG';
import PointyStarSVG from './PointyStarSVG';


interface StarProps extends StarType {
  onAnimationEnd: (id: number) => void;
}

const Star: React.FC<StarProps> = ({
  id,
  x,
  y,
  size,
  depth,
  shape,
  color,
  points,
  onAnimationEnd,
}) => {
  const [animationDuration] = useState(Math.random() * 2 + 2); // 2s to 4s
  const [rotation] = useState(Math.floor(Math.random() * 360));

  useEffect(() => {
    const timer = setTimeout(() => {
      onAnimationEnd(id);
    }, animationDuration * 1000);

    return () => clearTimeout(timer);
  }, [animationDuration, id, onAnimationEnd]);

  if (shape === 'star') {
    let random_number = Math.random()
    if (random_number > 0.5) {
      return (
        <AIStarSVG
          x={x}
          y={y}
          size={size}
          color={color}
          rotation={rotation}
          depth={depth}
          // points={points}
        />
      );
    }
    else {
      return (
        <PointyStarSVG
          x={x}
          y={y}
          size={size}
          color={color}
          rotation={rotation}
          depth={depth}
        />
      );
    }
  }

  const shapes: { [key: string]: string } = {
    dot: 'rounded-full',
    sparkle: 'sparkle',
    starburst: 'starburst',
  };

  return (
    <div
      className={`absolute ${shapes[shape]} animate-twinkle transform transition-transform duration-200 hover:scale-125 hover:brightness-125`}
      style={{
        left: x,
        top: y,
        width: `${size}px`,
        height: `${size}px`,
        transform: `rotate(${rotation}deg)`,
        animationDuration: `${animationDuration}s`,
        color: color,
        opacity: depth,
      }}
    ></div>
  );
};

export default memo(Star);