import React, { memo } from 'react';

interface AIStarSVGProps {
  x: number;
  y: number;
  size: number;
  color: string;
  rotation: number;
  depth: number;
}

const AIStarSVG: React.FC<AIStarSVGProps> = ({
  x,
  y,
  size,
  color,
  rotation,
  depth,
}) => {
  return (
    <svg
      className="absolute twinkle"
      style={{
        left: x,
        top: y,
        width: size,
        height: size,
        opacity: depth,
        overflow: 'visible',
      }}
      viewBox="0 0 110 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.42934 57.592C-0.491596 57.2228 -0.469033 53.0896 1.45296 52.7259C32.9184 46.7715 46.3207 31.7605 52.5447 1.39406C52.9338 -0.50476 56.936 -0.445556 57.2907 1.45997C63.0283 32.2869 78.4162 47.6158 108.517 53.084C110.428 53.4311 110.523 57.414 108.624 57.8184C78.5922 64.2122 63.3707 77.4373 57.4866 108.541C57.1235 110.46 52.9049 110.499 52.529 108.582C46.419 77.4143 32.82 63.6256 1.42934 57.592Z"
        fill={color}
      />
    </svg>
  );
};

export default memo(AIStarSVG);
