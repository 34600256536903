import React, { useState } from 'react';
import { WaitlistResponse } from '../types/waitlist';

const Waitlist = () => {
  const [email, setEmail] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<{ text: string; type: 'success' | 'error' } | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage(null);

    try {
        const response = await fetch('https://your-api-gateway-url.execute-api.region.amazonaws.com/prod/waitlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          email,
          timestamp: new Date().toISOString(),
          userAgent: navigator.userAgent,
          referrer: document.referrer
        }),
      });

      const data: WaitlistResponse = await response.json();

      if (data.success) {
        setMessage({ text: data.message, type: 'success' });
        setEmail('');
      } else {
        setMessage({ text: data.message, type: 'error' });
      }
    } catch (error) {
      setMessage({ 
        text: 'Failed to join the waitlist. Please try again.',
        type: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section 
      data-index="3" 
      className="content-section h-screen snap-start relative overflow-hidden bg-black"
    >
      {/* Background Image with scale to prevent blur edge artifacts */}
      <div className="absolute inset-0 scale-110">
        <div 
          className={`absolute inset-0 w-full h-full transition-all duration-500 ${isFocused ? '' : 'blur-3xl'}`}
          style={{
            backgroundImage: 'url(https://picsum.photos/seed/picsum/1920/1080)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      </div>
      
      {/* Content */}
      <div className="relative max-h-lg h-full flex items-center justify-center px-4">
        <div className="relative max-w-sm w-full rounded-xl p-8 overflow-hidden">
          {/* Background Image */}
          <div 
            className="absolute inset-0"
            style={{
              backgroundImage: 'url(https://picsum.photos/seed/picsum/1920/1080)',
              backgroundSize: 'cover',
              backgroundAttachment: 'fixed',
              backgroundPosition: 'center',
            }}
          />
          {/* Blur Overlay */}
          <div className="absolute inset-0 backdrop-blur bg-black/20" />
          
          {/* Content Container */}
          <div className="relative z-10">
            {/* Holes at the top of the form */}
            <div className="absolute transform  -top-12 left-1/2 -translate-x-1/2 flex">
              <div className="w-14 h-2 rounded-full bg-white" />
            </div>
            <div className="mt-10">
              <div className="text-center">
                <h3 className="text-sm text-white mb-2">JOIN SIMULATED.AI WAITLIST</h3>
                <h2 className="text-4xl font-bold mb-3 leading-tight text-white">
                    Immersive NPCs and Storyline that really stimulates your mind.
                </h2>
                <p className="text-white/80 mb-8">
                    Play a game that makes you feel seen, and entertained. 
                </p>
              </div>
            </div>

            {/* <form onSubmit={handleSubmit} className="space-y-4">
              <div className="relative">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                placeholder="Enter your email..."
                className="w-full px-4 py-3 rounded-xl bg-white/90 backdrop-blur-sm text-black placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-300 transition-all"
                required
                disabled={isLoading}
              />
              <div className="absolute right-4 top-1/2 -translate-y-1/2 text-sm text-gray-400">
                Press Enter
              </div>
              </div>
              {message && (
              <div className={`text-sm ${message.type === 'success' ? 'text-green-400' : 'text-red-400'}`}>
                {message.text}
              </div>
              )}
              <button
              type="submit"
              disabled={isLoading}
              className={`w-full px-6 py-3 bg-black text-white font-medium rounded-xl transition-colors ${
                isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-800'
              }`}
              >
              {isLoading ? 'Joining...' : 'Join Waitlist'}
              </button>
            </form> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Waitlist;
