import React, { useEffect } from 'react';

interface ShootingStarProps {
  id: number;
  onAnimationEnd: (id: number) => void;
}

const ShootingStar: React.FC<ShootingStarProps> = ({ id, onAnimationEnd }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onAnimationEnd(id);
    }, 1000); // Match the duration of the animation

    return () => clearTimeout(timer);
  }, [id, onAnimationEnd]);

  const startX = Math.random() * window.innerWidth;
  const startY = Math.random() * (window.innerHeight / 2); // Upper half

  return (
    <div
      className="absolute bg-white rounded-full animate-shooting-star"
      style={{
        width: '0.2rem',
        height: '0.2rem',
        left: startX,
        top: startY,
      }}
    ></div>
  );
};

export default ShootingStar;
